import React, { useEffect, useState } from "react";
import style from "./Configuration.module.css";
import { baseUrl } from "../Url";
import { IoCloseOutline } from "react-icons/io5";
import { MdEdit } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";

const Configuration = () => {
  const key = JSON.parse(localStorage.getItem("access-token"));

  const [selectedTab, setSelectedTab] = useState(0);
  const [learningLevelData, setLearningLevelData] = useState([
    {
      measure: "",
      condition: "",
      parameter: [],
      then: "",
      operator: "",
      isDisabled: false,
    },
  ]);
  const [learningPaceData, setLearningPaceData] = useState([
    {
      course_name: "",
      course_id: "",
      module: "",
      module_id: "",
      measure: "",
      condition: "",
      parameter: [],
      then: "",
      operator: "",
      isDisabled: false,
      modules: [],
    },
  ]);

  const [performanceData, setPerformanceData] = useState([
    {
      measure: "",
      condition: "",
      parameter: [],
      then: "",
      operator: "",
      isDisabled: false,
    },
  ]);
  const [ruleData, setRuleData] = useState([]);
  const [learningLevelSessionID, setLearningLevelSessionID] = useState("");
  const [learningPaceSessionID, setLearningPaceSessionID] = useState("");
  const [performanceSessionID, setPerformanceSessionID] = useState("");
  const [createPopup, setCreatePopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [createFields, setCreateFields] = useState({
    name: "",
    description: "",
    type: "",
    defaultImage: null,
    activeImage: null,
  });
  const [categoryList, setCategoryList] = useState([]);
  const [moduleList, setModuleList] = useState([]);
  const [orgList, setOrgList] = useState([]);
  const [selectedOrgId, setSelectedOrgId] = useState("");

  //learning level functions

  const handleInputChange = (index, field, value) => {
    const updatedSections = [...learningLevelData];
    updatedSections[index][field] = value;
    if (field === "condition") {
      if (value === "Between") {
        updatedSections[index].parameter = [
          updatedSections[index].parameter[0] || "",
          "",
        ];
      } else {
        updatedSections[index].parameter = [
          updatedSections[index].parameter[0] || "",
        ];
      }
    }

    setLearningLevelData(updatedSections);
  };

  const handleValuesChange = (index, valueIndex, value) => {
    const updatedSections = [...learningLevelData];
    updatedSections[index].parameter[valueIndex] = value;
    setLearningLevelData(updatedSections);
  };

  const handleOperationClick = (index, operation) => {
    setLearningLevelData((prevData) => {
      const updatedSections = [...prevData];
      if (updatedSections[index].operator) {
        if (updatedSections[index].operator === operation) {
          return prevData;
        }
        updatedSections[index].operator = operation;
      } else {
        updatedSections[index].operator = operation;
        updatedSections.push({
          measure: "",
          condition: "",
          parameter: [],
          then: "",
          operator: "",
        });
      }

      return updatedSections;
    });
  };

  const handleDeleteSection = (index) => {
    setLearningLevelData((prevData) => prevData.filter((_, i) => i !== index));
  };
  const handleEditClick = (sectionType) => {
    if (sectionType === "learningLevel") {
      const updatedSections = learningLevelData.map((section) => ({
        ...section,
        isDisabled: false,
      }));
      setLearningLevelData(updatedSections);
    } else if (sectionType === "learningPace") {
      const updatedSections = learningPaceData.map((section) => ({
        ...section,
        isDisabled: false,
      }));
      setLearningPaceData(updatedSections);
    } else if (sectionType === "performance") {
      const updatedSections = performanceData.map((section) => ({
        ...section,
        isDisabled: false,
      }));
      setPerformanceData(updatedSections);
    }
  };

  //learning Pace functions

  const handlePaceInputChange = (index, field, value, name = "") => {
    const updatedSections = [...learningPaceData];
    if (field === "course") {
      updatedSections[index].course_id = value;
      updatedSections[index].course_name = name;
      topicGet(value, index);
    } else if (field === "module") {
      updatedSections[index].module_id = value;
      updatedSections[index].module = name;
    } else {
      updatedSections[index][field] = value;
    }

    if (field === "condition") {
      updatedSections[index].parameter =
        value === "Between"
          ? [updatedSections[index].parameter[0] || "", ""]
          : [updatedSections[index].parameter[0] || ""];
    }

    setLearningPaceData(updatedSections);
  };
  const handlePaceValuesChange = (index, valueIndex, value) => {
    const updatedSections = [...learningPaceData];
    updatedSections[index].parameter[valueIndex] = value;
    setLearningPaceData(updatedSections);
  };

  const handlePaceOperationClick = (index, operation) => {
    setLearningPaceData((prevData) => {
      const updatedSections = [...prevData];
      if (updatedSections[index].operator) {
        if (updatedSections[index].operator === operation) {
          return prevData;
        }
        updatedSections[index].operator = operation;
      } else {
        updatedSections[index].operator = operation;
        updatedSections.push({
          course_name: "",
          course_id: "",
          module: "",
          module_id: "",
          measure: "",
          condition: "",
          parameter: [],
          then: "",
          operator: "",
          isDisabled: false,
          modules: [],
        });
      }

      return updatedSections;
    });
  };

  //Performance functions

  const handlePerformanceInputChange = (index, field, value) => {
    const updatedSections = [...performanceData];
    updatedSections[index][field] = value;
    if (field === "condition") {
      if (value === "Between") {
        updatedSections[index].parameter = [
          updatedSections[index].parameter[0] || "",
          "",
        ];
      } else {
        updatedSections[index].parameter = [
          updatedSections[index].parameter[0] || "",
        ];
      }
    }

    setPerformanceData(updatedSections);
  };

  const handlePerformanceValuesChange = (index, valueIndex, value) => {
    const updatedSections = [...performanceData];
    updatedSections[index].parameter[valueIndex] = value;
    setPerformanceData(updatedSections);
  };

  const handlePerformanceOperationClick = (index, operation) => {
    setPerformanceData((prevData) => {
      const updatedSections = [...prevData];
      if (updatedSections[index].operator) {
        if (updatedSections[index].operator === operation) {
          return prevData;
        }
        updatedSections[index].operator = operation;
      } else {
        updatedSections[index].operator = operation;
        updatedSections.push({
          measure: "",
          condition: "",
          parameter: [],
          then: "",
          operator: "",
          isDisabled: false,
        });
      }

      return updatedSections;
    });
  };

  //badge create function
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCreateFields((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setCreateFields((prev) => ({
      ...prev,
      [name]: files[0],
    }));
  };

  async function getRules() {
    await fetch(`${baseUrl}/get/gamification-rule/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setRuleData(data.data);
        } else {
          setRuleData([]);
        }
      });
  }

  const handleCreateSession = () => {
    const cleanedLearningLevelData = learningLevelData.map(
      ({ isDisabled, ...rest }) => rest
    );
    const cleanedLearningPaceData = learningPaceData.map(
      ({ isDisabled, modules, ...rest }) => rest
    );
    const cleanedPerformanceData = performanceData.map(
      ({ isDisabled, ...rest }) => rest
    );

    const data = {
      gamification_id: ruleData[0]?.gamification_id,
      sections: [
        {
          SectionName: "Learning_Level",
          rules: cleanedLearningLevelData,
          section_id: learningLevelSessionID || "",
          trigger: "On_Topic_Completions",
        },
        {
          SectionName: "Learning_Pace",
          rules: cleanedLearningPaceData,
          section_id: learningPaceSessionID || "",
          trigger: "Upon_Topic_Assessment_Completion",
        },
        {
          SectionName: "Performance",
          rules: cleanedPerformanceData,
          section_id: performanceSessionID || "",
          trigger: "Upon Point calculation",
        },
      ],
    };

    createSession(data);
  };

  async function getSession(id) {
    await fetch(`${baseUrl}/get/gamification-rule/individual`, {
      method: "POST",
      body: JSON.stringify({
        gamification_id: id,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true && data.data[0]?.sections?.length) {
          const learningLevelSection = data.data[0].sections.find(
            (section) => section.SectionName === "Learning_Level"
          );
          if (learningLevelSection) {
            setLearningLevelSessionID(learningLevelSection.section_id);
            const levelRules = learningLevelSection.rules.map((rule) => ({
              measure: rule.measure,
              condition: rule.condition,
              parameter: rule.parameter,
              then: rule.then,
              operator: rule.operator,
              isDisabled: true,
            }));
            setLearningLevelData(levelRules);
          }
          const learningPaceSection = data.data[0].sections.find(
            (section) => section.SectionName === "Learning_Pace"
          );
          if (learningPaceSection) {
            setLearningPaceSessionID(learningPaceSection.section_id);
            const paceRules = learningPaceSection.rules.map((rule) => ({
              course_name: rule.course_name || "",
              course_id: rule.course_id || "",
              module: rule.module || "",
              module_id: rule.module_id || "",
              measure: rule.measure,
              condition: rule.condition,
              parameter: rule.parameter,
              then: rule.then,
              operator: rule.operator,
              isDisabled: true,
              modules: [],
            }));
            setLearningPaceData(paceRules);
            paceRules.forEach((rule, index) => {
              if (rule.course_id) {
                topicGet(rule.course_id, index);
              }
            });
          }
          const performanceSection = data.data[0].sections.find(
            (section) => section.SectionName === "Performance"
          );
          if (performanceSection) {
            setPerformanceSessionID(performanceSection.section_id);
            const performanceRules = performanceSection.rules.map((rule) => ({
              measure: rule.measure,
              condition: rule.condition,
              parameter: rule.parameter,
              then: rule.then,
              operator: rule.operator,
              isDisabled: true,
            }));
            setPerformanceData(performanceRules);
          }
        } else {
          setLearningLevelData([
            {
              measure: "",
              condition: "",
              parameter: [],
              then: "",
              operator: "",
              isDisabled: false,
            },
          ]);
          setLearningPaceData([
            {
              course_name: "",
              course_id: "",
              module: "",
              module_id: "",
              measure: "",
              condition: "",
              parameter: [],
              then: "",
              operator: "",
              isDisabled: false,
              modules: [],
            },
          ]);
          setPerformanceData([
            {
              measure: "",
              condition: "",
              parameter: [],
              then: "",
              operator: "",
              isDisabled: false,
            },
          ]);
        }
      });
  }

  async function createSession(data) {
    await fetch(`${baseUrl}/create/sections`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          if (selectedTab === 0 && ruleData.length > 0) {
            getSession(ruleData[0].gamification_id);
          } else if (selectedTab === 1 && ruleData.length > 0) {
            getSession(ruleData[1].gamification_id);
          }
        } else {
        }
      });
  }

  async function categoryGet() {
    await fetch(`${baseUrl}/main/categories/get`, {
      method: "POST",
      body: JSON.stringify({
        Organization_ID: "6698e28b5cd20b9546e08da2",
        type: "admin",
        // club_id: ClubId,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setCategoryList(data.data);
      });
  }

  async function topicGet(id, index) {
    await fetch(`${baseUrl}/topic/get`, {
      method: "POST",
      body: JSON.stringify({
        org_id: "6698e28b5cd20b9546e08da2",
        category_id: id,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.status === true) {
          setLearningPaceData((prevData) => {
            const updatedData = [...prevData];
            updatedData[index].modules = data.data;
            return updatedData;
          });
        }
      });
  }

  useEffect(() => {
    getRules();
    categoryGet();
  }, [key]);

  useEffect(() => {
    if (selectedTab === 0 && ruleData.length > 0) {
      getSession(ruleData[0].gamification_id);
    } else if (selectedTab === 1 && ruleData.length > 0) {
      getSession(ruleData[1].gamification_id);
    }
  }, [selectedTab, ruleData]);

  return (
    <div className={style.container}>
      <div className={style.header}>
        <div className={style.headerText}>
          <p>Gamification Configurations</p>
        </div>
        <div className={style.headerButton}>
          <button onClick={handleCreateSession}>Save</button>
        </div>
      </div>
      <div className={style.sections}>
        <div className={style.tabHeader}>
          <div
            onClick={() => setSelectedTab(0)}
            className={`${style.tab} ${
              selectedTab === 0 ? style.activeTab : ""
            }`}
          >
            <p>Rewards point</p>
          </div>
          <div
            onClick={() => setSelectedTab(1)}
            className={`${style.tab} ${
              selectedTab === 1 ? style.activeTab : ""
            }`}
          >
            <p>Badge List</p>
          </div>
          <div
            onClick={() => setSelectedTab(2)}
            className={`${style.tab} ${
              selectedTab === 2 ? style.activeTab : ""
            }`}
          >
            <p>Badge configuration</p>
          </div>
        </div>
        <div className={style.tabSection}>
          {selectedTab === 0 && (
            <div className={style.rewardSection}>
              <div className={style.typeContainer}>
                <div className={style.typeHeader}>
                  <p>Learning Level</p>
                  {learningLevelSessionID ? (
                    <MdEdit onClick={() => handleEditClick("learningLevel")} />
                  ) : (
                    ""
                  )}
                </div>
                {learningLevelData.map((section, index) => (
                  <div key={index} className={style.learningSection}>
                    <div className={style.inputSection}>
                      <div className={style.inputContainer}>
                        <label>Measure</label>
                        <select
                          value={section.measure}
                          onChange={(e) =>
                            handleInputChange(index, "measure", e.target.value)
                          }
                          disabled={section.isDisabled}
                        >
                          <option value="">Select Measure</option>
                          <option value="Average Score">Average Score</option>
                        </select>
                      </div>

                      <div className={style.inputContainer}>
                        <label>Condition</label>
                        <select
                          value={section.condition}
                          onChange={(e) =>
                            handleInputChange(
                              index,
                              "condition",
                              e.target.value
                            )
                          }
                          disabled={section.isDisabled}
                        >
                          <option value="">Select Condition</option>
                          <option value="Above">Above</option>
                          <option value="Between">Between</option>
                          <option value="Below">Below</option>
                        </select>
                      </div>

                      <div className={style.inputContainer}>
                        <label>Parameters</label>
                        <select
                          value={section.parameter[0] || ""}
                          onChange={(e) =>
                            handleValuesChange(index, 0, e.target.value)
                          }
                          disabled={section.isDisabled}
                        >
                          <option value="">Select Value</option>
                          {Array.from({ length: 100 }, (_, i) => (
                            <option key={i + 1} value={i + 1}>
                              {i + 1}
                            </option>
                          ))}
                        </select>
                      </div>

                      {section.condition === "Between" && (
                        <div className={style.inputContainer}>
                          <label>Parameters</label>
                          <select
                            value={section.parameter[1] || ""}
                            onChange={(e) =>
                              handleValuesChange(index, 1, e.target.value)
                            }
                            disabled={section.isDisabled}
                          >
                            <option value="">Select Second Value</option>
                            {Array.from({ length: 100 }, (_, i) => (
                              <option key={i + 1} value={i + 1}>
                                {i + 1}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}

                      <div className={style.inputContainer}>
                        <label>Reward</label>
                        <input
                          type="text"
                          value={section.then}
                          onChange={(e) =>
                            handleInputChange(index, "then", e.target.value)
                          }
                          disabled={section.isDisabled}
                        />
                      </div>

                      <div className={style.buttonContainer}>
                        {section.operator === "" && !section.isDisabled && (
                          <button
                            onClick={() => handleOperationClick(index, "AND")}
                          >
                            AND
                          </button>
                        )}
                        {section.operator === "" && !section.isDisabled && (
                          <button
                            onClick={() => handleOperationClick(index, "OR")}
                          >
                            OR
                          </button>
                        )}
                        {section.operator &&
                          learningLevelData.length > 1 &&
                          !section.isDisabled && (
                            <button
                              style={{ background: "transparent" }}
                              onClick={() => handleDeleteSection(index)}
                            >
                              <IoCloseOutline size={20} />
                            </button>
                          )}
                      </div>
                    </div>

                    <div className={style.operator}>
                      {section.operator && (
                        <p className={style.operationText}>
                          {section.operator}
                        </p>
                      )}
                    </div>
                  </div>
                ))}
              </div>

              <div className={style.typeContainer}>
                <div className={style.typeHeader}>
                  <p>Learning Pace</p>
                  {learningPaceSessionID ? (
                    <MdEdit onClick={() => handleEditClick("learningPace")} />
                  ) : (
                    ""
                  )}
                </div>
                {learningPaceData.map((section, index) => (
                  <div className={style.learningSection}>
                    <div key={index} className={style.inputSection}>
                      <div className={style.inputContainer}>
                        <label>Course</label>
                        <select
                          value={section.course_id}
                          disabled={section.isDisabled}
                          onChange={(e) => {
                            const selectedOption =
                              e.target.options[e.target.selectedIndex];
                            handlePaceInputChange(
                              index,
                              "course",
                              e.target.value,
                              selectedOption.text
                            );
                          }}
                        >
                          <option value="">Select Course</option>
                          {categoryList &&
                            categoryList.length > 0 &&
                            categoryList.map((cat) => (
                              <option
                                key={cat.category_id}
                                value={cat.category_id}
                              >
                                {cat.Name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className={style.inputContainer}>
                        <label>Module</label>
                        <select
                          value={section.module_id}
                          disabled={section.isDisabled}
                          onChange={(e) => {
                            const selectedOption =
                              e.target.options[e.target.selectedIndex];
                            handlePaceInputChange(
                              index,
                              "module",
                              e.target.value,
                              selectedOption.text
                            );
                          }}
                        >
                          <option value="">Select Module</option>
                          {section.modules &&
                            section.modules.length > 0 &&
                            section.modules.map((mod) => (
                              <option key={mod.module_id} value={mod.module_id}>
                                {mod.module_name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className={style.inputContainer}>
                        <label>Measure</label>
                        <select
                          disabled={section.isDisabled}
                          value={section.measure}
                          onChange={(e) =>
                            handlePaceInputChange(
                              index,
                              "measure",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Measure</option>
                          <option value="Actual Duration">
                            Actual Duration
                          </option>
                        </select>
                      </div>

                      <div className={style.inputContainer}>
                        <label>Condition</label>
                        <select
                          disabled={section.isDisabled}
                          value={section.condition}
                          onChange={(e) =>
                            handlePaceInputChange(
                              index,
                              "condition",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Condition</option>
                          <option value="Above">Above</option>
                          <option value="Between">Between</option>
                          <option value="Below">Below</option>
                        </select>
                      </div>

                      <div className={style.inputContainer}>
                        <label>Parameters</label>
                        <select
                          disabled={section.isDisabled}
                          value={section.parameter[0] || ""}
                          onChange={(e) =>
                            handlePaceValuesChange(index, 0, e.target.value)
                          }
                        >
                          <option value="">Select Value</option>
                          {Array.from({ length: 100 }, (_, i) => (
                            <option key={i + 1} value={i + 1}>
                              {i + 1}weeks
                            </option>
                          ))}
                        </select>
                      </div>

                      {section.condition === "Between" && (
                        <div className={style.inputContainer}>
                          <label>Parameters</label>
                          <select
                            disabled={section.isDisabled}
                            value={section.parameter[1] || ""}
                            onChange={(e) =>
                              handlePaceValuesChange(index, 1, e.target.value)
                            }
                          >
                            <option value="">Select Value</option>
                            {Array.from({ length: 100 }, (_, i) => (
                              <option key={i + 1} value={i + 1}>
                                {i + 1}weeks
                              </option>
                            ))}
                          </select>
                        </div>
                      )}

                      <div className={style.inputContainer}>
                        <label>Reward</label>
                        <input
                          disabled={section.isDisabled}
                          type="text"
                          value={section.then}
                          onChange={(e) =>
                            handlePaceInputChange(index, "then", e.target.value)
                          }
                        />
                      </div>

                      <div className={style.buttonContainer}>
                        {section.operator === "" && !section.isDisabled && (
                          <button
                            onClick={() =>
                              handlePaceOperationClick(index, "AND")
                            }
                          >
                            AND
                          </button>
                        )}
                        {section.operator === "" && !section.isDisabled && (
                          <button
                            onClick={() =>
                              handlePaceOperationClick(index, "OR")
                            }
                          >
                            OR
                          </button>
                        )}
                      </div>
                    </div>
                    <div className={style.operator}>
                      {section.operator && (
                        <p className={style.operationText}>
                          {section.operator}
                        </p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
              <div className={style.typeContainer}>
                <div className={style.typeHeader}>
                  <p>Performance</p>
                  {performanceSessionID ? (
                    <MdEdit onClick={() => handleEditClick("performance")} />
                  ) : (
                    ""
                  )}
                </div>
                {performanceData.map((section, index) => (
                  <div className={style.learningSection}>
                    <div key={index} className={style.inputSection}>
                      <div className={style.inputContainer}>
                        <label>Measure</label>
                        <select
                          disabled={section.isDisabled}
                          value={section.measure}
                          onChange={(e) =>
                            handlePerformanceInputChange(
                              index,
                              "measure",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Measure</option>
                          <option value="Reward Points">Reward Points</option>
                        </select>
                      </div>

                      <div className={style.inputContainer}>
                        <label>Condition</label>
                        <select
                          disabled={section.isDisabled}
                          value={section.condition}
                          onChange={(e) =>
                            handlePerformanceInputChange(
                              index,
                              "condition",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Condition</option>
                          <option value="Above">Above</option>
                          <option value="Between">Between</option>
                          <option value="Below">Below</option>
                        </select>
                      </div>

                      <div className={style.inputContainer}>
                        <label>Parameters</label>
                        <input
                          disabled={section.isDisabled}
                          type="number"
                          value={section.parameter[0] || ""}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value >= 0 || value === "") {
                              handlePerformanceValuesChange(index, 0, value);
                            }
                          }}
                          placeholder="Enter value"
                        />
                      </div>

                      {section.condition === "Between" && (
                        <div className={style.inputContainer}>
                          <label>Parameters</label>
                          <input
                            disabled={section.isDisabled}
                            type="number"
                            value={section.parameter[1] || ""}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (value >= 0 || value === "") {
                                handlePerformanceValuesChange(index, 1, value);
                              }
                            }}
                            placeholder="Enter value"
                          />
                        </div>
                      )}

                      <div className={style.inputContainer}>
                        <label>Reward</label>
                        <select
                          disabled={section.isDisabled}
                          value={section.then}
                          onChange={(e) =>
                            handlePerformanceInputChange(
                              index,
                              "then",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Reward</option>
                          <option value="Bronze">Bronze</option>
                          <option value="Silver">Silver</option>
                          <option value="Gold">Gold</option>
                        </select>
                      </div>

                      <div className={style.buttonContainer}>
                        {section.operator === "" && !section.isDisabled && (
                          <button
                            onClick={() =>
                              handlePerformanceOperationClick(index, "AND")
                            }
                          >
                            AND
                          </button>
                        )}
                        {section.operator === "" && !section.isDisabled && (
                          <button
                            onClick={() =>
                              handlePerformanceOperationClick(index, "OR")
                            }
                          >
                            OR
                          </button>
                        )}
                      </div>
                    </div>
                    <div className={style.operator}>
                      {section.operator && (
                        <p className={style.operationText}>
                          {section.operator}
                        </p>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {selectedTab === 1 && (
            <div className={style.badgeContainer}>
              <div className={style.badgeHeader}>
                <p>Badge List</p>
                <button onClick={() => setCreatePopup(true)}>Create</button>
              </div>
              <div className={style.badgeTable}>
                <table>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Type</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Topic Ace</td>
                      <td>Scored {">"}85% for topic test</td>
                      <td>Topic and module</td>
                      <td>
                        <MdEdit
                          className={style.editIcon}
                          onClick={() => setEditPopup(true)}
                        />
                        <RiDeleteBinLine
                          className={style.deleteIcon}
                          onClick={() => setDeletePopup(true)}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          )}
          {selectedTab === 2 && (
            <div className={style.rewardSection}>
              <div className={style.typeContainer}>
                <div className={style.typeHeader}>
                  <p>Topics & Modules</p>
                </div>
                {performanceData.map((section, index) => (
                  <div className={style.learningSection}>
                    <div key={index} className={style.inputSection}>
                      <div className={style.inputContainer}>
                        <label>Measure</label>
                        <select
                          value={section.measure}
                          onChange={(e) =>
                            handlePerformanceInputChange(
                              index,
                              "measure",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Measure</option>
                          <option value="Reward Points">Reward Points</option>
                        </select>
                      </div>

                      <div className={style.inputContainer}>
                        <label>Condition</label>
                        <select
                          value={section.condition}
                          onChange={(e) =>
                            handlePerformanceInputChange(
                              index,
                              "condition",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Condition</option>
                          <option value="Above">Above</option>
                          <option value="Between">Between</option>
                          <option value="Below">Below</option>
                        </select>
                      </div>

                      <div className={style.inputContainer}>
                        <label>Parameters</label>
                        <input
                          type="number"
                          value={section.parameter[0] || ""}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value >= 0 || value === "") {
                              handlePerformanceValuesChange(index, 0, value);
                            }
                          }}
                          placeholder="Enter value"
                        />
                      </div>

                      {section.condition === "Between" && (
                        <div className={style.inputContainer}>
                          <label>Parameters</label>
                          <input
                            type="number"
                            value={section.parameter[1] || ""}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (value >= 0 || value === "") {
                                handlePerformanceValuesChange(index, 1, value);
                              }
                            }}
                            placeholder="Enter value"
                          />
                        </div>
                      )}

                      <div className={style.inputContainer}>
                        <label>Reward</label>
                        <select
                          value={section.then}
                          onChange={(e) =>
                            handlePerformanceInputChange(
                              index,
                              "then",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Reward</option>
                          <option value="Bronze">Bronze</option>
                          <option value="Silver">Silver</option>
                          <option value="Gold">Gold</option>
                        </select>
                      </div>

                      <div className={style.buttonContainer}>
                        <button
                          onClick={() =>
                            handlePerformanceOperationClick(index, "AND")
                          }
                        >
                          AND
                        </button>
                        <button
                          onClick={() =>
                            handlePerformanceOperationClick(index, "OR")
                          }
                        >
                          OR
                        </button>
                      </div>
                    </div>
                    <div className={style.operator}>
                      {section.operator && (
                        <p className={style.operationText}>
                          {section.operator}
                        </p>
                      )}
                    </div>
                  </div>
                ))}
              </div>

              {/* <div className={style.typeContainer}>
                <div className={style.typeHeader}>
                  <p>Daily Drill</p>
                </div>
                {performanceData.map((section, index) => (
                  <div className={style.learningSection}>
                    <div key={index} className={style.inputSection}>
                      <div className={style.inputContainer}>
                        <label>Measure</label>
                        <select
                          value={section.measure}
                          onChange={(e) =>
                            handlePerformanceInputChange(
                              index,
                              "measure",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Measure</option>
                          <option value="Reward Points">Reward Points</option>
                        </select>
                      </div>

                      <div className={style.inputContainer}>
                        <label>Condition</label>
                        <select
                          value={section.condition}
                          onChange={(e) =>
                            handlePerformanceInputChange(
                              index,
                              "condition",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Condition</option>
                          <option value="Above">Above</option>
                          <option value="Between">Between</option>
                          <option value="Below">Below</option>
                        </select>
                      </div>

                      <div className={style.inputContainer}>
                        <label>Parameters</label>
                        <input
                          type="number"
                          value={section.values[0] || ""}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value >= 0 || value === "") {
                              handlePerformanceValuesChange(index, 0, value);
                            }
                          }}
                          placeholder="Enter value"
                        />
                      </div>

                      {section.condition === "Between" && (
                        <div className={style.inputContainer}>
                          <label>Parameters</label>
                          <input
                            type="number"
                            value={section.values[0] || ""}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (value >= 0 || value === "") {
                                handlePerformanceValuesChange(index, 1, value);
                              }
                            }}
                            placeholder="Enter value"
                          />
                        </div>
                      )}

                      <div className={style.inputContainer}>
                        <label>Reward</label>
                        <select
                          value={section.reward}
                          onChange={(e) =>
                            handlePerformanceInputChange(
                              index,
                              "reward",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Reward</option>
                          <option value="Bronze">Bronze</option>
                          <option value="Silver">Silver</option>
                          <option value="Gold">Gold</option>
                        </select>
                      </div>

                      <div className={style.buttonContainer}>
                        <button
                          onClick={() =>
                            handlePerformanceOperationClick(index, "AND")
                          }
                        >
                          AND
                        </button>
                        <button
                          onClick={() =>
                            handlePerformanceOperationClick(index, "OR")
                          }
                        >
                          OR
                        </button>
                      </div>
                    </div>
                    <div className={style.operator}>
                      {section.operation && (
                        <p className={style.operationText}>
                          {section.operation}
                        </p>
                      )}
                    </div>
                  </div>
                ))}
              </div>

              <div className={style.typeContainer}>
                <div className={style.typeHeader}>
                  <p>Weekly Workout</p>
                </div>
                {performanceData.map((section, index) => (
                  <div className={style.learningSection}>
                    <div key={index} className={style.inputSection}>
                      <div className={style.inputContainer}>
                        <label>Measure</label>
                        <select
                          value={section.measure}
                          onChange={(e) =>
                            handlePerformanceInputChange(
                              index,
                              "measure",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Measure</option>
                          <option value="Reward Points">Reward Points</option>
                        </select>
                      </div>

                      <div className={style.inputContainer}>
                        <label>Condition</label>
                        <select
                          value={section.condition}
                          onChange={(e) =>
                            handlePerformanceInputChange(
                              index,
                              "condition",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Condition</option>
                          <option value="Above">Above</option>
                          <option value="Between">Between</option>
                          <option value="Below">Below</option>
                        </select>
                      </div>

                      <div className={style.inputContainer}>
                        <label>Parameters</label>
                        <input
                          type="number"
                          value={section.values[0] || ""}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value >= 0 || value === "") {
                              handlePerformanceValuesChange(index, 0, value);
                            }
                          }}
                          placeholder="Enter value"
                        />
                      </div>

                      {section.condition === "Between" && (
                        <div className={style.inputContainer}>
                          <label>Parameters</label>
                          <input
                            type="number"
                            value={section.values[0] || ""}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (value >= 0 || value === "") {
                                handlePerformanceValuesChange(index, 1, value);
                              }
                            }}
                            placeholder="Enter value"
                          />
                        </div>
                      )}

                      <div className={style.inputContainer}>
                        <label>Reward</label>
                        <select
                          value={section.reward}
                          onChange={(e) =>
                            handlePerformanceInputChange(
                              index,
                              "reward",
                              e.target.value
                            )
                          }
                        >
                          <option value="">Select Reward</option>
                          <option value="Bronze">Bronze</option>
                          <option value="Silver">Silver</option>
                          <option value="Gold">Gold</option>
                        </select>
                      </div>

                      <div className={style.buttonContainer}>
                        <button
                          onClick={() =>
                            handlePerformanceOperationClick(index, "AND")
                          }
                        >
                          AND
                        </button>
                        <button
                          onClick={() =>
                            handlePerformanceOperationClick(index, "OR")
                          }
                        >
                          OR
                        </button>
                      </div>
                    </div>
                    <div className={style.operator}>
                      {section.operation && (
                        <p className={style.operationText}>
                          {section.operation}
                        </p>
                      )}
                    </div>
                  </div>
                ))}
              </div> */}
            </div>
          )}
        </div>
      </div>
      {createPopup && (
        <>
          <div className={style.popupOverlay}></div>
          <div className={style.popup}>
            <div className={style.popupHead}>
              <p>Create Popup</p>
            </div>
            <div className={style.popupContent}>
              <div className={style.popupInput}>
                <label>Name</label>
                <input
                  name="name"
                  placeholder="Name"
                  value={createFields.name}
                  onChange={handleChange}
                />
              </div>
              <div className={style.popupInput}>
                <label>Description</label>
                <textarea
                  name="description"
                  rows={3}
                  placeholder="Description"
                  value={createFields.description}
                  onChange={handleChange}
                />
              </div>
              <div className={style.popupInput}>
                <label>Type</label>
                <select
                  name="type"
                  value={createFields.type}
                  onChange={handleChange}
                >
                  <option disabled value="">
                    Select type
                  </option>
                  <option value="Topic and module">Topic and module</option>
                  <option value="Daily Drill">Daily Drill</option>
                  <option value="Weekly Workout">Weekly Workout</option>
                </select>
              </div>
              <div className={style.popupInput}>
                <label>Default image</label>
                <input
                  name="defaultImage"
                  type="file"
                  onChange={handleFileChange}
                />
              </div>
              <div className={style.popupInput}>
                <label>Active image</label>
                <input
                  name="activeImage"
                  type="file"
                  onChange={handleFileChange}
                />
              </div>
            </div>
            <div className={style.popupButton}>
              <button
                className={style.cancel}
                onClick={() => setCreatePopup(false)}
              >
                Cancel
              </button>
              <button className={style.create}>Create</button>
            </div>
          </div>
        </>
      )}

      {editPopup && (
        <>
          <div className={style.popupOverlay}></div>
          <div className={style.popup}>
            <div className={style.popupHead}>
              <p>Create Popup</p>
            </div>
            <div className={style.popupContent}>
              <div className={style.popupInput}>
                <label>Name</label>
                <input name="name" placeholder="Name" />
              </div>
              <div className={style.popupInput}>
                <label>Description</label>
                <textarea
                  name="description"
                  rows={3}
                  placeholder="Description"
                />
              </div>
              <div className={style.popupInput}>
                <label>Type</label>
                <select name="type">
                  <option disabled value="">
                    Select type
                  </option>
                  <option value="Topic and module">Topic and module</option>
                  <option value="Daily Drill">Daily Drill</option>
                  <option value="Weekly Workout">Weekly Workout</option>
                </select>
              </div>
              <div className={style.popupInput}>
                <label>Default image</label>
                <input name="defaultImage" type="file" />
              </div>
              <div className={style.popupInput}>
                <label>Active image</label>
                <input name="activeImage" type="file" />
              </div>
            </div>
            <div className={style.popupButton}>
              <button
                className={style.cancel}
                onClick={() => setEditPopup(false)}
              >
                Cancel
              </button>
              <button className={style.create}>Create</button>
            </div>
          </div>
        </>
      )}

      {deletePopup && (
        <>
          <div className={style.popup}>
            <div className={style.popupHead}>
              <p>Delete Popup</p>
            </div>
            <div className={style.popupContent}>
              <div className={style.popupText}>
                <p>Are you sure you want to delete?..</p>
              </div>
            </div>
            <div className={style.popupButton}>
              <button
                className={style.cancel}
                onClick={() => setDeletePopup(false)}
              >
                Cancel
              </button>
              <button className={style.create}>Update</button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Configuration;
